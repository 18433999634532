const API_KEY = 'AIzaSyBiQ_-A9znDY05FsF5AOhio-367qwSKQDM';
const LAT = 50.3965621;
const LNG = 30.5312652;
const ZOOM = 17.65;

function initMap(n = 1) {
    google.maps.Map.prototype.setCenterWithOffset = function (latlng, offsetX, offsetY) {
        var map = this;
        var ov = new google.maps.OverlayView();
        ov.onAdd = function () {
            var proj = this.getProjection();
            var aPoint = proj.fromLatLngToContainerPixel(latlng);
            aPoint.x = aPoint.x + offsetX;
            aPoint.y = aPoint.y + offsetY;
            map.panTo(proj.fromContainerPixelToLatLng(aPoint));
            //map.setCenter(proj.fromContainerPixelToLatLng(aPoint));
        }
        ov.draw = function () { };
        ov.setMap(this);
    };
    var markers = new Array();
    var infowindow = new google.maps.InfoWindow({
        //pixelOffset: new google.maps.Size(-230,250)
    });
    var locations = [
        [new google.maps.LatLng(LAT, LNG)],
        // [new google.maps.LatLng(53.700055, 27.5513694)],
        // [new google.maps.LatLng(53.809055, 27.5813694)],
        // [new google.maps.LatLng(53.859055, 27.5013694)],
    ]
    var options = {
        zoom: ZOOM,
        panControl: false,
        mapTypeControl: false,
        center: locations[0][0],
        //styles: [{ "featureType": "landscape.natural", "elementType": "geometry.fill", "stylers": [{ "visibility": "on" }, { "color": "#e0efef" }] }, { "featureType": "poi", "elementType": "geometry.fill", "stylers": [{ "visibility": "on" }, { "hue": "#1900ff" }, { "color": "#c0e8e8" }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "lightness": 100 }, { "visibility": "simplified" }] }, { "featureType": "road", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "visibility": "on" }, { "lightness": 700 }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#7dcdcd" }] }],
        scrollwheel: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    var map = new google.maps.Map(document.getElementById('map'), options);
    var icon = {
        url: '', //img/interface/map-marker.png
        scaledSize: new google.maps.Size(18, 20),
        anchor: new google.maps.Point(9, 10)
    }
    for (var i = 0; i < locations.length; i++) {
        var marker = new google.maps.Marker({
            icon: icon,
            position: locations[i][0],
            map: map,
        });
        google.maps.event.addListener(marker, 'click', (function (marker, i) {
            return function () {
                for (var m = 0; m < markers.length; m++) {
                    markers[m].setIcon(icon);
                }
                var cnt = i + 1;
                //infowindow.setContent(document.querySelector('.events-map__item_' + cnt).innerHTML);
                //infowindow.open(map, marker);


                //MARKER
                //marker.setIcon(icon);
                //map.setCenterWithOffset(marker.getPosition(), 0, 0);
                setTimeout(function () {

                }, 10);
            }
        })(marker, i));
        markers.push(marker);
    }
    if (n) {
        var nc = n - 1;
        setTimeout(function () {
            google.maps.event.trigger(markers[nc], 'click');
        }, 500);
    }
}
function mapAdd() {
    let tag = document.createElement('script');
    tag.async = true
    tag.defer = true
    tag.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=initMap`
    //tag.src = "https://maps.google.com/maps/api/js?sensor=false&amp;key=AIzaSyDXAw45Va1r3T6QDLaPFnL_r2EqdPQ7KW0&callback=mapInit";
    let wrapper = document.querySelector('.wrapper');
    wrapper.insertAdjacentElement('beforeend', tag)
}
if (document.querySelector('#map')) {
    window.initMap = initMap
    mapAdd();
    //setTimeout(() => initMap(), 5000)
}


/* YA
function map(n) {
	ymaps.ready(init);
	function init() {
		// Создание карты.
		var myMap = new ymaps.Map("map", {
			// Координаты центра карты.
			// Порядок по умолчанию: «широта, долгота».
			// Чтобы не определять координаты центра карты вручную,
			// воспользуйтесь инструментом Определение координат.
			controls: [],
			center: [43.585525, 39.723062],
			// Уровень масштабирования. Допустимые значения:
			// от 0 (весь мир) до 19.
			zoom: 10
		});

		let myPlacemark = new ymaps.Placemark([43.585525, 39.723062], {
		},{
			// Опции.
			//balloonContentHeader: 'Mistoun',
			//balloonContentBody: 'Москва, Николоямская 40с1',
			//balloonContentFooter: '+ 7(495) 507-54 - 90',
			//hasBalloon: true,
			//hideIconOnBalloonOpen: true,

			hasBalloon: false,
			hideIconOnBalloonOpen: false,
			// Необходимо указать данный тип макета.
			iconLayout: 'default#imageWithContent',
			// Своё изображение иконки метки.
			iconImageHref: 'img/icons/map.svg',
			// Размеры метки.
			iconImageSize: [40, 40],
			// Смещение левого верхнего угла иконки относительно
			// её "ножки" (точки привязки).
			iconImageOffset: [-20, -20],
			// Смещение слоя с содержимым относительно слоя с картинкой.
			iconContentOffset: [0, 0],
		});
		myMap.geoObjects.add(myPlacemark);

		myMap.behaviors.disable('scrollZoom');
		myMap.behaviors.disable('drag');
	}
}
*/